import { useWindowSize } from "@kanpla/system";
import { Spinner } from "@kanpla/ui";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";
import LoginContent from "../components/login/LoginContent";
import SidebarBranded from "../components/SidebarBranded";

const Login = () => {
  const { t } = useTranslation(["translation"]);
  const { auth } = useContainer(AppContext);

  const [isLoggedIn, setIsLoggedIn] = useLocalstorageState("user_logged_in");
  const { width: screeWidth } = useWindowSize();

  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    setTimeout(() => setTimedOut(true), 5000);
    return () => setTimedOut(false);
  }, []);

  return (
    <div className="flex flex-col md:flex-row h-full kanpla-bg-primary min-h-screen items-center">
      {screeWidth >= 768 && <SidebarBranded displayStoreButtons={true} />}
      <div
        id="new-school-container"
        className="relative flex-1 h-full w-full pt-5 pr-5 pl-5 pb-10 md:pb-0 overflow-y-auto flex justify-center items-center flex-col"
      >
        <div className="w-full text-center my-auto">
          {isLoggedIn ? (
            <div className="flex flex-col h-100 w-100 justify-center items-center text-primary-main">
              <Spinner useCurrentColor size="medium" />
              <div className="mt-8">
                {timedOut && (
                  <p className="text-text-secondary text-sm">
                    {t("translation:problems-with-login")}
                  </p>
                )}
                <Button
                  type={timedOut ? "primary" : "text"}
                  className="text-text-primary mt-2"
                  onClick={() => {
                    auth.signOut();
                    setIsLoggedIn(false);
                  }}
                >
                  {t("translation:log-out")}
                </Button>
              </div>
            </div>
          ) : (
            <LoginContent />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
